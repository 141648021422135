import * as React from "react";

function useAudio(url) {
  const audioRef = React.useRef(null);

  const [currentTime, setCurrentTime] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [isPlaying, setPlaying] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [isSeeking, setSeeking] = React.useState(false);
  const [isLooping, setLooping] = React.useState(false);
  const [isEnding, setEnding] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
  }, [url]);

  return [
    <audio
      onLoadedData={() => {
        setPlaying(false);
        setLoading(false);
        setEnding(false);
        if (audioRef.current) setDuration(audioRef.current.duration);
      }}
      onSeeking={() => setSeeking(true)}
      onSeeked={() => setSeeking(false)}
      src={url}
      loop={isLooping}
      ref={audioRef}
      onTimeUpdate={() => {
        if (audioRef.current) setCurrentTime(audioRef.current.currentTime);
      }}
      hidden
      crossOrigin="anonymous"
      onEnded={() => {
        setPlaying(false);
        setEnding(true);
      }}
    ></audio>,

    {
      currentTime,
      duration,
      isPlaying,
      isSeeking,
      isLoading,
      isEnding,
      setEnding: (p) => setEnding(p),
      setLooping: () => setLooping(true),
      setPlaying: (props) => {
        if (props === true) {
          audioRef.current && audioRef.current.play();
          setPlaying(true);
        }
        if (props === false) {
          audioRef.current && audioRef.current.pause();
          setPlaying(false);
        }
      },
      progress: (currentTime / duration) * 100,
      setTime: (seconds) => {
        if (audioRef.current) audioRef.current.currentTime = seconds;
      },
      togglePlaybackStatus: () => {
        if (isPlaying === true) {
          audioRef.current && audioRef.current.pause();
          setPlaying(false);
        }
        if (isPlaying === false) {
          audioRef.current && audioRef.current.play();
          setPlaying(true);
        }
      },
    },
  ];
}

export default useAudio;
