import React from "react";

export const OverlayNoGPS = (props) => {
  return (
    <div class="overlay">
      <div class="overlay__content">
        <h1>{false && props.intro && props.intro.name || "Kein GPS vorhanden!"}</h1>
        <p>
          { props.intro && props.intro.description ||`Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta ipsum
          placeat autem voluptatem ut? Quas aperiam culpa ab quisquam cumque
          consequuntur voluptates perferendis nemo mollitia, sunt aut officia
          nesciunt! Delectus?`}
        </p>
        <div class="button button-square" onClick={e=>props.toggleOverlayNoGPSButton()}>{ props.intro && props.intro.buttontext || "Los geht's" }</div>
      </div>
    </div>
  );
};
