import { Link } from "preact-router";

const AudioListEntry = (props) => {
  let isPlaying =
    props.playerFeature.currentTrack.audiofile &&
    props.audiofile &&
    props.playerFeature.currentTrack.audiofile.url == props.audiofile.url &&
    props.playerFeature.isPlaying;
  return (
    <li class="audio-items__single">
      {props.thumbnailImage ? (
        <img
          class="audio-items__single__image"
          src={
            "https://legendeholzdorf-backend.einshorn.de" +
            props.thumbnailImage.url
          }
          alt="image-title"
        />
      ) : (
        <img
          class="audio-items__single__image"
          src="/assets/img/photo-1.jpg"
          alt="image-title"
        />
      )}
      <div class="audio-items__single__text">
        <a href="#0">
          <div
            class="audio-items__single__title"
            onClick={() => {
              isPlaying
                ? props.playerController.togglePlaybackStatus()
                : props.playerController.playOneTrack(props, props.station.id);
            }}
          >
            {props.name || "??"}
          </div>
          {props.subtitle && (
            <div class="audio-items__single__meta">{props.subtitle}</div>
          )}
        </a>
      </div>
      <button
        class="button-round button-round--small button-round--border"
        onClick={() => {
          isPlaying
            ? props.playerController.togglePlaybackStatus()
            : props.playerController.playOneTrack(props, props.station.id);
        }}
      >
        <svg class="icon icon--blue">
          {isPlaying ? (
            <use xlinkHref="#icon-pause"></use>
          ) : (
            <use xlinkHref="#icon-play"></use>
          )}
        </svg>
      </button>
    </li>
  );
};
export default AudioListEntry;
