import { Component, createContext, h } from "preact";
import { route, Router } from "preact-router";
import { useContext, useState } from "preact/hooks";
import inside from "./pointInGeoJson";

import Home from "../routes/home";
import Station from "../routes/station";
import Tour from "../routes/tour";
import { fetchAll } from "./fetchData";
import { OverlayGPSInfo } from "./OverlayGPSInfo";

import PageWrap from "./PageWrap";
import Player from "./Player";
import Sidebar from "./Sidebar";
import SVGSymbols from "./SVGSymbols";
import { MapBig } from "./MapBig_";
import Body from "./Body";
import { OverlayNoGPS } from "./OverlayNoGPS";
import { uniq } from "../uniq";
import AudioListEntry from "./AudioListEntry";
import Contributors from "../routes/contributors";
import Imprint from "../routes/imprint";
import Privacy from "../routes/privacy";
import Map from "../routes/map";
import useMobileDetect from "./useMobileDetect";
import { OverlayStationChange } from "./OverlayStationChange";

const messageChannel = new MessageChannel();

class ApppP extends Component {
  constructor(props) {
    super();
    this.state = {
      fetched: false, // wurden die Daten gefetched?
      serviceWorkerRegistration: false,
      serviceWorkerAvailable: false,
      postToursToServiceWorker: false,
      postStationsToServiceWorker: false,
      requestedDownloadTour: false,
      downloadedTourIds: [], // kommen vom Service Worker
      onTour: false, // hat der User eine Tour gestartet?
      playerFeature: {
        // Player für die Beiträge
        showPlayer: false,
        isPlaying: false, //s
        currentTrack: {},
      },
      playerBackground: {
        // Player für die Backgroundsound
        isPlaying: false,
        matched: null, // Wurde ein Sound gematched
        currentTrack: {
          id: null,
          audiofile: {
            url: "/uploads/BRS_Teich_1_c14cc79611.mp3", // wegen useAudio
          },
        },
      },
      geolocation: {
        permissionForPosition: false,
        error: false,
        position: false, //long,lat
        showOverlayGPSInfo: true,
        showOverlayNoGPS: false,
        changed: false,
      },
      showOverlayStationChange: false,
      onStationChange: null, // Stationid wo er denkt er sei
      currentStation: null, // aktuelle Station
      currentTour: null, // aktuelle Tour
      playingStation: null, // abzuspielende Station
      playerController: {
        show: (call) => {
          this.setState(
            {
              playerFeature: {
                ...this.state.playerFeature,
                showPlayer: true,
              },
            },
            call
          );
        },
        hide: (call) => {
          this.setState({
            playerFeature: {
              ...this.state.playerFeature,
              showPlayer: false,
              showPlayerButtonHeader: true,
              isPlaying: false, //TODO: little error  audioRef.current is null
            },
            playerBackground: {
              ...this.state.playerBackground,
              isPlaying: false,
              matched: null,
            },
          });
        },
        resetBackground: (call) => {
          this.setState(
            {
              playerBackground: {
                ...this.state.playerBackground,
                isPlaying: false,
                matched: null,
              },
            },
            call
          );
        },
        play: () => {
          this.setState({
            playerFeature: {
              ...this.state.playerFeature,
              isPlaying: true,
            },
          });
          if (this.state.playerBackground.matched) {
            this.setState({
              playerBackground: {
                ...this.state.playerBackground,
                isPlaying: true,
              },
            });
          }
          this.state.setBackgroundTrack();
        },
        pause: (call) => {
          this.setState(
            {
              playerFeature: {
                ...this.state.playerFeature,
                isPlaying: false,
              },
            },
            call
          );
        },
        pauseB: (call) => {
          this.setState(
            {
              playerBackground: {
                ...this.state.playerBackground,
                isPlaying: false,
              },
            },
            call
          );
        },
        playB: (call) => {
          this.setState(
            {
              playerBackground: {
                ...this.state.playerBackground,
                isPlaying: true,
              },
            },
            call
          );
        },
        nextFeature: () => {
          let nameOfCurrentTrack = this.state.playerFeature.currentTrack.name;
          let indexOfCurrentTrack = this.state.playingStation.audiofiles.findIndex(
            (a) => a.name == nameOfCurrentTrack
          );
          let indexOfNextTrack = indexOfCurrentTrack + 1;
          let nextTrack = this.state.playingStation.audiofiles[
            indexOfNextTrack
          ];
          if (nextTrack) {
            this.setState(
              {
                playerFeature: {
                  ...this.state.playerFeature,
                  currentTrack: nextTrack,
                  showPlayer: true,
                  isPlaying: false,
                },
              },
              this.state.playerController.play
            );
          }
        },
        prevFeature: () => {
          let nameOfCurrentTrack = this.state.playerFeature.currentTrack.name;
          let indexOfCurrentTrack = this.state.playingStation.audiofiles.findIndex(
            (a) => a.name == nameOfCurrentTrack
          );
          let indexOfNextTrack = indexOfCurrentTrack - 1;
          let nextTrack = this.state.playingStation.audiofiles[
            indexOfNextTrack
          ];
          if (nextTrack) {
            this.setState(
              {
                playerFeature: {
                  ...this.state.playerFeature,
                  currentTrack: nextTrack,
                  showPlayer: true,
                  isPlaying: false,
                },
              },
              this.state.playerController.play
            );
          }
        },
        togglePlaybackStatus: () => {
          this.state.playerFeature.isPlaying
            ? this.state.playerController.pause()
            : this.state.playerController.play();
        },
        playStation: (station) => {
          let currentTrack = station.audiofiles[0];

          this.state.playerController.playOneTrack(currentTrack, station.id);
        },
        playTour: () => {
          //Route to Station
          let nextStationUrl =
            "/tour/" +
            this.state.currentTour.id +
            "/station/" +
            this.state.currentStation.id;
          route(nextStationUrl, true);
          this.state.playerController.playStation(this.state.currentStation);
        },
        playOneTrack: (currentTrack, stationId) => {
          if (this.state.playerFeature.isPlaying) {
            this.state.setPlayingStation(stationId, () =>
              this.setState(
                {
                  playerFeature: {
                    ...this.state.playerFeature,
                    currentTrack: currentTrack,
                    showPlayer: true,
                    isPlaying: false,
                  },
                },
                this.state.playerController.play
              )
            );
          } else
            this.state.setPlayingStation(
              stationId,
              this.setState(
                {
                  playerFeature: {
                    ...this.state.playerFeature,
                    currentTrack: currentTrack,
                    showPlayer: true,
                  },
                },
                this.state.playerController.play
              )
            );
        },
      },
      toggleSidebar: () =>
        this.setState({ showSidebar: !this.state.showSidebar || false }),
      toggleOverlayStationChange: () =>
        this.setState({
          showOverlayStationChange: !this.state.showOverlayStationChange,
        }),
      toggleOverlayGPSInfoButton: (props) =>
        this.setState({
          geolocation: {
            ...this.state.geolocation,
            showOverlayGPSInfo: false,
            permissionForPosition: props,
          },
        }),
      toggleOverlayNoGPSButton: () =>
        this.setState({
          geolocation: {
            ...this.state.geolocation,
            showOverlayNoGPS: !this.state.geolocation.showOverlayNoGPS,
          },
        }),
      routeToStation: (id) => {
        let tour = this.state.tours.find((tour) =>
          tour.stationsSorted.find((station) => station.station.id == id)
        );
        route("/tour/" + tour.id + "/station/" + id);
      },
      setBTrack: (backgroundSound, call) => {
        this.setState(
          {
            playerBackground: {
              ...this.state.playerBackground,
              currentTrack: backgroundSound,
              matched: true,
            },
          },
          call
        );
      },
      setBackgroundTrack: () => {
        var backgroundSound = {};
        var position = [0, 0];
        console.log("setBackgroundTrack");
        //var position = [11.278261095285416,50.9408426595798];
        if (this.state.geolocation.position && !this.state.geolocation.error) {
          position = this.state.geolocation.position;
        } else if (this.state.playingStation) {
          var location = this.state.playingStation.location;
          //var location = this.state.locations.find((l) => l.id == locationId);
          position = location.marker;
        }
        // It's not LatLng its LngLat!1!!
        position = [position[1], position[0]];
        var match = this.state.matchBackgroundSound(position);
        if (!match) return null;
        var newMatch = match != this.state.playerBackground.currentTrack.id;
        var isPlaying = this.state.playerBackground.isPlaying;

        if (match && newMatch && this.state.backgroundsounds) {
          backgroundSound = this.state.backgroundsounds.find(
            (b) => b.id == match
          );
          if (isPlaying) {
            this.state.playerController.pauseB(() =>
              this.state.setBTrack(
                backgroundSound,
                this.state.playerController.playB
              )
            );
          } else {
            this.state.setBTrack(
              backgroundSound,
              this.state.playerController.playB
            );
          }
        } //nichts gefunden
      },
      setCurrentTour: (props) => {
        this.setState({ currentTour: props });
      },
      setCurrentStation: (props) => {
        console.log("setCurrentStation");
        this.setState({ currentStation: props });
      },
      setPlayingStation: (id, callback) => {
        var station = this.state.currentStation;
        this.setState({ playingStation: station }, callback);
      },
      setCurrentTrack: (props) => {
        console.log("setCurrentTrack");
        this.setState({
          playerFeature: {
            ...this.state.playerFeature,
            currentTrack: props,
          },
        });
      },
      matchBackgroundSound(position) {
        console.log("matchBackgroundSound()");
        let result = -1;
        let features = this.backgroundsounds
          ? this.backgroundsounds.map((b) => ({
              ...b.area,
              properties: { id: b.id },
            }))
          : [];
        let geojson = {
          type: "FeatureCollection",
          features: features,
        };
        try {
          result = inside.feature(geojson, position);
        } catch (e) {
          console.log(e);
        }
        result = result.properties ? result.properties.id : false;
        return result;
      },
      requestDownloadTour: (id) => {
        this._requestDownloadTour(id);
      },
    };
  }
  componentDidMount() {
    this.requestFetch();
    this.getServiceWorkerRegistration();
  }
  componentDidUpdate(prevProps, prevState) {
    //TODO!
    if (this.state.geolocation.permissionForPosition) this.getPosition();
    if (this.state.geolocation.position != prevState.geolocation.position)
      this.state.setBackgroundTrack();

    this.checkServiceWorker();
    this.postToursToServiceWorker();
    this.postStationsToServiceWorker();
  }

  _requestDownloadTour(id) {
    if (
      this.state.serviceWorkerAvailable &&
      this.state.postStationsToServiceWorker &&
      this.state.postToursToServiceWorker &&
      !this.state.requestedDownloadTour &&
      !this.state.downloadedTourIds.find((t) => t == id)
    ) {
      this.setState({ requestedDownloadTour: true }, () => {
        this._worker.active.postMessage({
          type: "REQUEST_DOWNLOAD",
          payload: {
            tourId: id,
          },
        });
      });
    }
  }

  postToursToServiceWorker() {
    if (
      this.state.serviceWorkerAvailable &&
      !this.state.postToursToServiceWorker &&
      this.state.tours
    ) {
      this._worker.active.postMessage({
        type: "SEND_TOURS",
        payload: this.state.tours,
      });
      this.setState({
        postToursToServiceWorker: true,
      });
    }
  }

  postStationsToServiceWorker() {
    if (
      this.state.serviceWorkerAvailable &&
      !this.state.postStationsToServiceWorker &&
      this.state.stations
    ) {
      this._worker.active.postMessage({
        type: "SEND_STATIONS",
        payload: this.state.stations,
      });
      this.setState({
        postStationsToServiceWorker: true,
      });
    }
  }

  requestFetch() {
    if (!this.state.fetched) {
      fetchAll((props) => this.setState(props));
      this.setState({ fetched: true });
    }
  }

  getServiceWorkerRegistration() {
    if ("serviceWorker" in navigator && !this.state.serviceWorkerAvailable) {
      navigator.serviceWorker
        .getRegistrations()
        .then((e) => (this._worker = e[0]));
      this.setState({ serviceWorkerRegistration: true });
    }
  }

  checkServiceWorker() {
    if (
      this._worker &&
      this._worker.active &&
      this.state.serviceWorkerRegistration &&
      !this.state.serviceWorkerAvailable
    ) {
      this._worker.active.postMessage(
        {
          type: "INIT_PORT",
        },
        [messageChannel.port2]
      );
      this.setState({ serviceWorkerAvailable: true });

      // Listen to the response
      messageChannel.port1.onmessage = (event) => {
        if (event.data) {
          switch (event.data.type) {
            case "DOWNLOADED_TOUR_IDS":
              console.log("RX DOWNLOADED_TOUR_IDS");
              this.setState({
                downloadedTourIds: event.data.payload.downloadedTourIds,
              });
              break;
            case "RESPONSE_DOWNLOAD":
              console.log("RX RESPONSE_DOWNLOAD");
              let downloadedTourIds = this.state.downloadedTourIds;
              let downlodadedTourId = event.data.payload.tourId;
              downloadedTourIds.push(downlodadedTourId);
              downloadedTourIds = uniq(downloadedTourIds);
              this.setState({
                requestedDownloadTour: false,
                downloadedTourIds: downloadedTourIds,
              });

              break;

            default:
              break;
          }
        }
      };
    }
  }

  askStationChange(position) {
    if (this.state.playerFeature.showPlayer) {
      let pos = [position[1], position[0]];
      let matchedId = this.matchStation(pos);
      if (matchedId == -1) return null;
      let newMatch = matchedId != this.state.currentStation.id;
      newMatch = newMatch && matchedId != this.state.onStationChange;
      if (newMatch) {
        this.state.toggleOverlayStationChange();
        this.setState({
          onStationChange: matchedId,
        });
      }
    }
  }

  matchStation(position) {
    console.log("matchStation()");
    let result = -1;
    let features = this.state.stations
      ? this.state.stations.map((b) => ({
          ...b.location.area,
          properties: { id: b.id },
        }))
      : [];
    let geojson = {
      type: "FeatureCollection",
      features: features,
    };
    try {
      result = inside.feature(geojson, position);
    } catch (e) {
      console.log(e);
    }
    result = result.properties ? result.properties.id : result;
    return result;
  }

  getPosition() {
    console.log("getPosition");
    const defaultSettings = {
      enableHighAccuracy: false,
      timeout: Infinity,
      maximumAge: 0,
    };
    const onChange = ({ coords, timestamp }) => {
      let latlong = [coords.latitude, coords.longitude];
      if (
        latlong[0] != this.state.geolocation.position[0] &&
        latlong[1] != this.state.geolocation.position[1]
      ) {
        this.askStationChange(latlong);
        this.setState({
          geolocation: {
            ...this.state.geolocation,
            position: latlong,
            changed: !this.state.geolocation.changed,
          },
        });
      }
    };
    const onError = (error) => {
      console.log(error.message);
      if (this.state.geolocation.error == null) {
        this.setState({
          geolocation: {
            ...this.geolocation,
            error: true,
            showOverlayNoGPS: true,
          },
        });
      }
    };

    navigator.geolocation.watchPosition(onChange, onError, defaultSettings);
  }

  render() {
    const detectMobile = useMobileDetect();
    return (
      <Body
        player={this.state.playerFeature.showPlayer}
        overlay={
          this.state.geolocation.showOverlayGPSInfo ||
          this.state.geolocation.showOverlayNoGPS ||
          this.state.showOverlayStationChange
        }
        sidebar={this.state.showSidebar}
      >
        <Sidebar {...this.state} />
        <PageWrap>
          <Router>
            <Home path="/" {...this.state} />
            <Tour path="/tour/:id?" {...this.state} />
            <Station path="/tour/:id?/station/:id?" {...this.state} />
            <Contributors path="/contributors" {...this.state} />
            <Imprint path="/imprint" {...this.state} />
            <Privacy path="/privacy" {...this.state} />
            <Map path="/map" {...this.state} />
            <Dev path="/dev" {...this.state} />
          </Router>
          {this.state.playerFeature.showPlayer && <Player {...this.state} />}
        </PageWrap>

        {this.state.geolocation.showOverlayGPSInfo && (
          <OverlayGPSInfo {...this.state} />
        )}
        {this.state.showOverlayStationChange && (
          <OverlayStationChange {...this.state} />
        )}
        {this.state.geolocation.showOverlayNoGPS && (
          <OverlayNoGPS {...this.state} />
        )}
        {detectMobile.isDesktop() && this.state.stations && (
          <MapBig {...this.state} />
        )}
        <SVGSymbols />
      </Body>
    );
  }
}

export default ApppP;

const Dev = (props) => (
  <>
    <code>dev gps:</code>
    <br />
    <code style={{ color: props.geolocation.changed ? "red" : "black" }}>
      {props.geolocation && props.geolocation.position[0]}
    </code>{" "}
    <br />
    <code style={{ color: props.geolocation.changed ? "red" : "black" }}>
      {props.geolocation && props.geolocation.position[1]}
    </code>
    <br />
  </>
);
