import { useContext, useEffect } from "preact/hooks";
import useAudio from "../useAudio";
import { route } from "preact-router";
import InputRange from "react-input-range";
import "../../style/player.css";

const Player = (props) => {
  const [audioFeatureElement, audioFeatureProps] = useAudio(
    "https://legendeholzdorf-backend.einshorn.de" +
      props.playerFeature.currentTrack.audiofile.url ||
      "https://legendeholzdorf-backend.einshorn.de/uploads/test_talk_d3b1456da3.mp3"
  );
  const [audioBackgroundElement, audioBackgroundProps] = useAudio(
    "https://legendeholzdorf-backend.einshorn.de" +
      props.playerBackground.currentTrack.audiofile.url ||
      "https://legendeholzdorf-backend.einshorn.de/uploads/BRS_Teich_1_c14cc79611.mp3"
  );

  if (props.playerFeature.isPlaying != audioFeatureProps.isPlaying) {
    //play
    props.playerFeature.isPlaying && !audioFeatureProps.isEnding
      ? audioFeatureProps.setPlaying(true)
      : audioFeatureProps.setPlaying(false);
    //pause at end
    if (!audioFeatureProps.isPlaying && audioFeatureProps.isEnding) {
      props.playerController.pause();
      audioFeatureProps.setEnding(false);
    }
  }

  if (props.playerBackground.isPlaying != audioBackgroundProps.isPlaying) {
    audioBackgroundProps.setLooping();
    props.playerBackground.isPlaying
      ? audioBackgroundProps.setPlaying(true)
      : audioBackgroundProps.setPlaying(false);
  }

  let nameOfCurrentTrack = props.playerFeature.currentTrack.name;
  let numberOfTracks = props.playingStation.audiofiles.length;
  let indexOfCurrentTrack = props.playingStation.audiofiles.findIndex(
    (a) => a.name == nameOfCurrentTrack
  );
  let numberOfCurrentTrack = indexOfCurrentTrack + 1;
  let firstTrack = false;
  let lastTrack = false;
  if (indexOfCurrentTrack == 0) firstTrack = true;
  if (numberOfTracks == numberOfCurrentTrack) lastTrack = true;

  let durationInMin = Math.floor(audioFeatureProps.duration / 60);
  durationInMin = ("0" + durationInMin).slice(-2);
  let durationInSec = Math.floor(
    audioFeatureProps.duration - durationInMin * 60
  );
  durationInSec = ("0" + durationInSec).slice(-2);

  let currentTimeInMin = Math.floor(audioFeatureProps.currentTime / 60);
  currentTimeInMin = ("0" + currentTimeInMin).slice(-2);
  let currentTimeInSec = Math.floor(
    audioFeatureProps.currentTime - currentTimeInMin * 60
  );
  currentTimeInSec = ("0" + currentTimeInSec).slice(-2);

  return (
    <>
      {props.playerFeature.showPlayer && audioFeatureElement}
      {props.playerFeature.showPlayer &&
        props.playerBackground.matched &&
        audioBackgroundElement}
      <aside class=" player-active player  is-active ">
        <div class="player__buttons">
          <button
            class={
              firstTrack
                ? "button-round button-round--small is-disabled"
                : "button-round button-round--small"
            }
            aria-label="vorheriger Beitrag"
            onClick={() => props.playerController.prevFeature()}
          >
            <svg class="icon icon--blue">
              <use xlinkHref="#icon-prev"></use>
            </svg>
          </button>
          <button
            class="button-round button-round--big"
            tabindex="0"
            aria-label="Abspielen/Pausieren"
            onClick={() => props.playerController.togglePlaybackStatus()}
          >
            {audioFeatureProps.isPlaying == true ? <PauseIcon /> : <PlayIcon />}
          </button>
          <button
            class={
              lastTrack
                ? "button-round button-round--small is-disabled"
                : "button-round button-round--small"
            }
            aria-label="nächster Beitrag"
            onClick={() => props.playerController.nextFeature()}
          >
            <svg class="icon icon--blue">
              <use xlinkHref="#icon-next"></use>
            </svg>
          </button>
        </div>

        <div class="player__meta">
          <div>
            <div class="title" aria-label="Tourentitel">
              {props.playerFeature.currentTrack.name || "Parkanlage"}
            </div>
            {false && (
              <div class="subtitle" aria-label="Stationstitel">
                Sanierung
              </div>
            )}
          </div>
          <div class="track-num">
            <span>{numberOfCurrentTrack || "?"}</span>/
            <span>{props.playingStation.audiofiles.length || "04"}</span>
          </div>
        </div>

        <InputRange
          maxValue={100}
          minValue={0}
          value={audioFeatureProps.progress}
          onChange={(e) =>
            audioFeatureProps.setTime((e / 100) * audioFeatureProps.duration)
          }
        />

        <div class="player__time">
          <div class="player__time-elapsed">
            {currentTimeInMin || "60"}:{currentTimeInSec || "00"}
          </div>
          <div class="player__time-total">
            {durationInMin || "60"}:{durationInSec || "00"}
          </div>
        </div>

        <div class="player__nav">
          {props.currentStation.startOfTour ? (
            <button
              class="button-square player__prev-station "
              onClick={() => route(props.currentTour.url)}
            >
              zur Tourseite
            </button>
          ) : (
            <button
              class="button-square player__prev-station "
              onClick={() =>
                route(
                  props.currentTour.url +
                    "/station/" +
                    props.currentStation.idPrev +
                    "/"
                )
              }
            >
              Vorherige
            </button>
          )}
          {props.currentStation.endOfTour ? (
            <button
              class="button-square player__prev-station "
              onClick={() => {
                window.scrollTo(0, 0);
                route(props.currentTour.url);
              }}
            >
              zur Tourseite
            </button>
          ) : (
            <button
              class="button-square player__prev-station "
              onClick={() => {
                window.scrollTo(0, 0);
                route(
                  props.currentTour.url +
                    "/station/" +
                    props.currentStation.idNext +
                    "/"
                );
              }}
            >
              Nächste
            </button>
          )}
        </div>
      </aside>
    </>
  );
};

const PauseIcon = () => (
  <svg class="icon icon--blue">
    <use xlinkHref="#icon-pause"></use>
  </svg>
);
const PlayIcon = () => (
  <svg class="icon icon--blue">
    <use xlinkHref="#icon-play"></use>
  </svg>
);

export default Player;
