import { useEffect } from "preact/hooks";
import React from "react";

export const OverlayStationChange = (props) => {
  useEffect(() => window.scrollTo(0, 0));
  return (
    <div class="overlay">
      <div class="overlay__content">
        <h1>
          {(props.stationswechsel && props.stationswechsel.title) ||
            "New Station?"}
        </h1>
        <p>
          {(props.stationswechsel && props.stationswechsel.description) ||
            `Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta ipsum
          placeat autem voluptatem ut? Quas aperiam culpa ab quisquam cumque
          consequuntur voluptates perferendis nemo mollitia, sunt aut officia
          nesciunt! Delectus?`}
        </p>

        <section
          class="station-buttons"
          style="padding: 0 ; margin-bottom: 0px; background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%; margin-left: -10px; margin-right: -10px;"
        >
          <button
            class="button button-square"
            onClick={(e) => {
              props.toggleOverlayStationChange();
              props.routeToStation(props.onStationChange);
            }}
          >
            Ja
          </button>
          <button
            class="button button-square button-square--blue"
            onClick={(e) => props.toggleOverlayStationChange()}
          >
            Nein
          </button>
        </section>
      </div>
    </div>
  );
};
