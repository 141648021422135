import React from "react";

export const OverlayGPSInfo = (props) => {
  return (
    <div class="overlay">
      <div class="overlay__content">
        <h1>{(props.intro && props.intro.name) || ""}</h1>
        <p>
          {(props.intro && props.intro.description) ||
            "Audioguide wird geladen..."}
        </p>
        {props.intro && (
          <section
            class="station-buttons"
            style="padding: 0 ; margin-bottom: 0px; background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%; margin-left: -10px; margin-right: -10px;"
          >
            <button
              class="button button-square"
              onClick={(e) => props.toggleOverlayGPSInfoButton(true)}
            >
              {(props.intro && props.intro.buttontext) || "Los geht's"}
            </button>
            <button
              class="button button-square button-square--blue"
              onClick={(e) => props.toggleOverlayGPSInfoButton(false)}
            >
              Überspringen
            </button>
          </section>
        )}
      </div>
    </div>
  );
};
