export function fetchAll(callback) {
  let apiUrl = "https://legendeholzdorf-backend.einshorn.de/";
  let urls = [
    "intro",
    "homepage",
    "tours",
    "stations",
    "locations",
    "backgroundsounds",
    "mitwirkende",
    "imprint",
    "datenschutz",
    "stationswechsel",
  ];
  let result = {};

  Promise.all(
    urls.map((url) =>
      fetch(apiUrl + url)
        .then(checkStatus)
        .then(parseJSON)
        .then((data) => (result[url] = data))
        .catch((error) => console.log("There was a problem!", error))
    )
  ).then((e) => callback(result));

  function checkStatus(response) {
    if (response.ok) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
  }

  function parseJSON(response) {
    return response.json();
  }
}
